
















import { getPostText } from "@/includes/PostHelpers";
import { PostMessage } from "../../PostMessage";

import { Component, Emit, Prop } from 'vue-property-decorator';
import Vue from "vue";

@Component({
  methods: {
    getPostText
  },
})
export default class PostActionMessageValuePreview extends Vue {
  @Prop() model!: { message: PostMessage }

  @Prop() disabled!: boolean

  @Emit()
  actionValueClick() {

  }
}
