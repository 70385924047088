


























import NotifyUsersActionModel from "./NotifyUsersActionModel";
import PostActionMessageValuePreview from "../components/PostActionMessage/PostActionMessageValuePreview.vue";

import UsersInfoMixin from 'piramis-base-components/src/shared/logic/getUsersInfo/UsersInfoMixin'
import { PostType } from "piramis-base-components/src/shared/modules/posting/types";

import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import { plainToInstance } from "class-transformer";
import { PostMessage } from "../PostMessage";

@Component({
  components: {
    PostActionMessageValuePreview
  }
})
export default class NotifyUsersActionView extends Mixins(UsersInfoMixin) {
  @Prop() model!: NotifyUsersActionModel

  @Prop() disabled!: boolean

  @Emit()
  actionValueClick() {

  }

  get boardKey() {
    return this.$store.state.boardsState.activeBoard!.board
  }

  get message() {
    return plainToInstance(PostMessage, { message: {
      type: PostType.Post,
      variants: this.model.message
    } })
  }

  format(tag: number) {
    return this.formatter(this.model.usersInfoService.usersInfos, tag.toString())
  }

  created() {
    this.model.usersInfoService.getUsersInfos(this.model.users)
  }
}
